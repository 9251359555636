.textWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-3);
  font-weight: var(--font-weight-medium);
  font-size: var(--typography-base-sizes-small-font-size);
  line-height: var(--typography-base-sizes-small-line-height);
  color: var(--alpha-white-70);
  text-align: center;
}

.Dropdown {
  height: max-content;
  position: relative;
  outline-color: transparent;

  .content {
    position: absolute;
    top: calc(100% + var(--spacing-0-5));
    right: 0;
    width: var(--width-w-36);
    padding: var(--spacing-1);
    border-radius: var(--border-radius-md);
    background-color: var(--base-popover);
    border: 1px solid var(--base-border);
    box-shadow: var(--shadow--md);

    font-weight: var(--font-weight-normal);
    font-size: var(--typography-base-sizes-small-font-size);
    line-height: var(--typography-typography-components-small-line-height);
    color: var(--base-popover-foreground);

    .listItem {
      height: var(--height-h-8);
      padding: var(--spacing-1-5) var(--spacing-2);
      display: flex;
      align-items: center;
      gap: var(--spacing-2);
      border-radius: var(--border-radius-sm);
      cursor: pointer;

      &:hover,
      &:focus {
        background-color: var(--base-accent);
        color: var(--base-accent-foreground);
        outline: none;
      }

      &.disabled {
        opacity: var(--opacity-opacity-50);
        cursor: not-allowed;

        &:hover,
        &:focus {
          background-color: inherit;
        }
      }
    }

    .divider {
      position: relative;
      margin: var(--spacing-1) 0;
      height: 1px;
      width: calc(100% + var(--spacing-2));
      left: calc(0px - var(--spacing-1));
      background-color: var(--base-muted);
    }
  }
}

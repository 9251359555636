@import "src/styles/mixins";

.popoverOpenButton {
  margin-top: var(--spacing-2);
  margin-bottom: var(--spacing-1);
  width: 100%;
  padding: var(--spacing-2) var(--spacing-4);
  height: var(--height-h-10);
  border: 1px solid var(--base-input);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: var(--spacing-2);
  transition: background-color 0.15s;

  &:hover {
    background-color: var(--base-accent);
  }

  &.hasError {
    border-color: var(--base-destructive);
  }

  &.disabled {
    pointer-events: none;
    opacity: var(--opacity-opacity-50);
    cursor: not-allowed;
  }

  &.hasDate {
    .text,
    .icon {
      color: var(--base-foreground);
    }
  }

  .text {
    font-weight: var(--font-weight-normal);
    font-size: var(--typography-base-sizes-small-font-size);
    line-height: var(--typography-base-sizes-small-line-height);
    color: var(--base-muted-foreground);
  }

  .icon {
    color: var(--base-muted-foreground);
  }
}

.content {
  padding: var(--width-w-px);
}

.popoverContentContainer {
  & > div {
    @include media(sm) {
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
    }
  }
}

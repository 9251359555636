@import "src/styles/mixins";

.AppSidebar {
  .headerContainer {
    padding-top: var(--spacing-3);

    .header {
      padding: var(--spacing-2);
      outline-color: var(--base-ring);

      &:focus-visible {
        outline: 1px solid var(--base-ring);
        outline-width: 2px;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .list {
      padding: var(--spacing-2);
    }
    .progressBarContainer {
      padding: var(--spacing-2);
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: var(--spacing-2) var(--spacing-2);
      border-radius: var(--border-radius-sm);

      &:focus-visible {
        outline: solid var(--base-ring);
        outline-width: 2px;
      }

      .chevronRight {
        width: var(--width-w-4);
        height: var(--width-w-4);
        flex-shrink: 0;
        color: var(--base-sidebar-foreground);
      }
    }
  }

  .footer {
    padding: var(--spacing-2) 0;
    border-top: 1px solid var(--base-border);

    button {
      width: 100%;
      height: 44px;
      padding: var(--spacing-2) var(--spacing-4);
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: var(--spacing-4);
      border-radius: var(--border-radius-md);
      transition: all 0.15s;

      &:focus-visible {
        outline: solid var(--base-ring);
        outline-width: 2px;
      }

      &:hover {
        background-color: var(--base-sidebar-accent);
      }

      .info {
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .name {
          font-weight: var(--font-weight-semibold);
          font-size: var(--typography-base-sizes-small-font-size);
          line-height: var(--typography-base-sizes-small-line-height);
          color: var(--base-sidebar-foreground);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .phone {
          font-weight: var(--font-weight-normal);
          font-size: var(--typography-base-sizes-extra-small-font-size);
          line-height: var(--typography-base-sizes-extra-small-font-size);
          color: var(--base-sidebar-foreground);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      svg {
        flex-shrink: 0;
      }
    }
  }
}

.dropdownLinks {
  padding: var(--spacing-2) var(--spacing-2);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4);
  font-weight: var(--font-weight-normal);
  font-size: var(--typography-base-sizes-small-font-size);
  line-height: var(--typography-base-sizes-small-line-height);
  color: var(--base-muted-foreground);
  outline-color: var(--base-ring);

  .terms {
    text-decoration: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-underline-offset: 2px;
    cursor: pointer;
    outline-color: var(--base-ring);
  }

  a {
    outline-color: var(--base-ring);
  }

  .socials {
    display: flex;
    gap: var(--spacing-4);
    align-items: center;
    outline-color: var(--base-ring);
  }
}

@import "src/styles/mixins";

.container {
  height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include media(notMobile) {
    min-height: 700px;
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-8);
    padding: var(--spacing-8) var(--spacing-4) var(--spacing-8);
    overflow: auto;
    flex: 1;

    @include media(notMobile) {
      height: 100%;
      padding: var(--spacing-8);
      flex-direction: row;
      align-items: center;
      overflow: hidden;
    }

    .heroContainer {
      flex: 1;
      height: 100%;

      @include media(notMobile) {
        flex: 1 0;
      }

      @include media(md) {
        height: 600px;
      }

      @include media(lg) {
        height: 600px;
      }
    }

    .contentContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include media(notMobile) {
        flex: 1 0;
        align-items: center;
        order: -1;
      }

      .content {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: var(--spacing-6);

        @include media(notMobile) {
          max-width: var(--max-width-max-w-md);
        }

        h2 {
          display: none;
          font-weight: var(--font-weight-bold);
          font-size: var(--typography-base-sizes-large-font-size);
          line-height: var(--typography-base-sizes-large-line-height);
          color: var(--base-foreground);

          @include media(notMobile) {
            display: block;
          }
        }

        .otpContainer {
          display: flex;
          flex-direction: column;
          gap: var(--spacing-4);

          .info {
            height: var(--height-h-9);
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-weight: var(--font-weight-normal);
            font-size: var(--typography-base-sizes-small-font-size);
            line-height: var(--typography-base-sizes-small-line-height);
            color: var(--base-muted-foreground);
          }

          button {
            padding: var(--spacing-2) var(--spacing-3);
            height: var(--height-h-9);
            align-self: center;
            width: max-content;
          }
        }

        .desktopButton {
          display: none;

          @include media(notMobile) {
            display: block;
          }
        }
      }
    }
  }

  .buttonContainer {
    flex-shrink: 0;
    padding: var(--spacing-4) var(--spacing-4) var(--spacing-6);
    width: 100%;
    height: var(--height-h-20);
    border-top: 1px solid var(--base-input);

    @include media(notMobile) {
      display: none;
    }
  }
}

.button {
  padding: var(--spacing-2) var(--spacing-4);
  display: flex;
  justify-content: center;
  gap: var(--spacing-2);
  align-items: center;
  position: relative;
  height: var(--height-h-10);
  border-radius: var(--border-radius-md);
  outline-color: var(--base-ring);
  transition: all 0.15s;

  font-weight: var(--font-weight-medium);
  font-size: var(--typography-base-sizes-small-font-size);
  line-height: var(--typography-base-sizes-small-line-height);
  user-select: none;

  &:focus-visible {
    outline: solid var(--base-ring);
    outline-width: 2px;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.15s;
  }

  &:hover {
    &::after {
      opacity: 1;
    }
  }

  &:disabled {
    opacity: var(--opacity-opacity-50);
    pointer-events: none;
  }

  &.onlyIcon {
    padding: 0;
    height: var(--height-h-10);
    width: var(--width-w-10);
  }

  &.hide {
    display: none;
  }
}

.loading {
  opacity: var(--opacity-opacity-50);
  pointer-events: none;
  cursor: none;
}

.spinner {
  width: var(--width-w-4);
  height: var(--height-h-4);
  animation: spin 2s linear infinite;
  transform-origin: center;
}

/* Keyframes for rotating the spinner */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.primary {
  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(0, 0, 0, 0.4)
    ),
    var(--base-primary);
  color: var(--base-primary-foreground);

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: var(--alpha-white-10);
    transition: opacity 0.15s;
  }
}

.secondary {
  background-color: var(--base-secondary);
  color: var(--base-secondary-foreground);

  &::after {
    background: var(--alpha-white-20);
  }
}

.outline {
  background-color: var(--base-background);
  color: var(--base-foreground);
  border: 1px solid var(--base-input);

  &:hover, &.active {
    color: var(--base-accent-foreground);
    background-color: var(--base-accent);
  }

  &::after {
    content: none;
  }
}

.ghost {
  background-color: var(--tailwind-colors-base-transparent);
  color: var(--base-foreground);

  &:hover, &.active {
    color: var(--base-secondary-foreground);
    background: var(--base-accent);
  }

  &::after {
    content: none;
  }
}


.link {
  background-color: var(--tailwind-colors-base-transparent);
  color: var(--base-primary);
  transition: none;

  &:hover, &.active {
    color: var(--base-primary);
    background: inherit;
  }

  &::after {
    content: none;
  }
}

.otp {
  max-width: 100%;

  & > p {
    margin-top: var(--spacing-1);
  }

  &.hasError {
    .slot {
      border-color: var(--base-destructive);
      border-left-color: transparent;

      &:first-child {
        border-left-color: var(--base-destructive);
      }
    }
  }

  .list {
    display: flex;
  }

  .slot {
    min-width: 0;
    font-weight: var(--font-weight-normal);
    font-size: var(--typography-base-sizes-small-font-size);
    line-height: var(--typography-typography-components-small-line-height);
    color: var(--base-foreground);
    transition: none;
    border-radius: 0;
    border-left-color: transparent;
    caret-color: currentColor;
    text-align: center;

    &:focus {
      outline: 2px solid var(--base-ring);
      outline-offset: -2px;
    }

    &:first-child {
      border-left: 1px solid var(--base-input);
      border-radius: var(--border-radius-md) 0 0 var(--border-radius-md);
    }

    &:last-child {
      border-radius: 0 var(--border-radius-md) var(--border-radius-md) 0;
    }
  }
}

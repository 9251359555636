.Switch {
  display: flex;
  gap: var(--spacing-2);

  .switchContainer {
    flex-shrink: 0;
    position: relative;
    width: var(--width-w-11);
    height: var(--height-h-6);

    .switchInput {
      opacity: 0;
      width: 0;
      height: 0;

      &:checked + .switchLabel {
        background-color: var(--base-primary);
      }

      &:disabled + .switchLabel {
        background-color: var(--base-muted-foreground);
        cursor: not-allowed;
      }
    }

    .switchLabel {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: var(--base-input);
      border-radius: var(--border-radius-full);
      transition: background-color 0.15s;

      &::before {
        content: "";
        position: absolute;
        top: var(--spacing-0-5);
        left: var(--spacing-0-5);
        width: var(--width-w-5);
        height: var(--height-h-5);
        background-color: var(--base-background);
        border-radius: inherit;
        box-shadow: var(--shadow--lg);
        transition: transform 0.15s;
      }
    }

    .switchInput:checked + .switchLabel::before {
      transform: translateX(var(--spacing-5));
    }
  }

  .labelContainer {
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-1);

    .title {
      font-weight: var(--font-weight-medium);
      font-size: var(--typography-base-sizes-small-font-size);
      line-height: var(--typography-typography-components-small-line-height);
      color: var(--base-foreground);
    }

    .description {
      font-weight: var(--font-weight-normal);
      font-size: var(--typography-base-sizes-small-font-size);
      line-height: var( --typography-base-sizes-small-line-height);
      color: var(--base-muted-foreground);
    }
  }
}

.Animation {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius-xl);
  padding: var(--spacing-9) var(--spacing-8) var(--spacing-8);

  .blobs {
    position: absolute;
    background: black;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: inherit;
    container-type: size;
    isolation: isolate;

    &:after {
      content: "";
      backdrop-filter: blur(80px);
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: inherit;
      will-change: backdrop-filter;
      -webkit-backdrop-filter: blur(80px);
    }

    .blob {
      width: 50%;
      height: 50%;
      border-radius: 100%;
      position: absolute;
      transform-origin: center;
      will-change: transform, opacity;

      &.blobA {
        animation: circularA 25s ease-in-out infinite;
        background-color: var(--tailwind-colors-sky-950);
      }

      &.blobB {
        animation: circularB 20s ease-in-out infinite;
        background-color: var(--tailwind-colors-sky-500);
      }

      &.blobC {
        animation: circularC 30s ease-in-out infinite;
        background-color: var(--tailwind-colors-indigo-500);
      }
    }
  }

  @keyframes circularA {
    0% {
      transform: translate(0, 0) scale(2);
    }
    25% {
      transform: translate(100%, 0);
    }
    50% {
      transform: translate(100%, 100%) scale(1);
    }
    75% {
      transform: translate(0, 100%);
    }
    100% {
      transform: translate(0, 0) scale(2);
    }
  }

  @keyframes circularB {
    0% {
      transform: translate(50%, 50%) scale(1);
    }
    25% {
      transform: translate(50%, 0);
    }
    50% {
      transform: translate(0, 50%) scale(2);
    }
    75% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(50%, 50%) scale(1);
    }
  }

  @keyframes circularC {
    0% {
      transform: translate(50%, 0) scale(1);
    }
    25% {
      transform: translate(0, 100%);
    }
    50% {
      transform: translate(0, 50%) scale(1.5);
    }
    75% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(50%, 0) scale(1);
    }
  }

  .content {
    height: 100%;
    flex: 1;
    position: relative;
    z-index: 1;
  }
}

.secondary {
  .blobs {
    .blob {
      &.blobA {
        background-color: var(--tailwind-colors-teal-700);
      }

      &.blobB {
        background-color: var(--tailwind-colors-green-500);
      }

      &.blobC {
        background-color: var(--tailwind-colors-red-500);
      }
    }
  }
}

@container (height < 400px) {
  .blobs:after {
    content: "";
    backdrop-filter: blur(30px) !important;
    -webkit-backdrop-filter: blur(30px) !important;
    will-change: backdrop-filter;
  }

  .blob {
    width: 90% !important;
    height: 90% !important;
  }
}

@container (height < 100px) {
  .blobs:after {
    content: "";
    backdrop-filter: blur(4px) !important;
    -webkit-backdrop-filter: blur(4px) !important;
    will-change: backdrop-filter;
  }

  .blob {
    width: 90% !important;
    height: 90% !important;
  }
}

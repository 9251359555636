$breakpoint-sm: 640px;
$breakpoint-md: 768px;
$breakpoint-lg: 1024px;
$breakpoint-xl: 1280px;
$breakpoint-2xl: 1536px;

$notMobile: "only screen and (min-width: #{$breakpoint-md})";
$sm: "only screen and (max-width: #{$breakpoint-md - 1})";
$md: "only screen and (min-width: #{$breakpoint-md}) and (max-width: #{$breakpoint-lg - 1})";
$lg: "only screen and (min-width: #{$breakpoint-lg}) and (max-width: #{$breakpoint-xl - 1})";
$xl: "only screen and (min-width: #{$breakpoint-xl}) and (max-width: #{$breakpoint-2xl - 1})";
$xl2: "only screen and (min-width: #{$breakpoint-2xl})";

@mixin media($type) {
  $media: $notMobile;

  @if $type == notMobile {
    $media: $notMobile;
  } @else if $type == sm {
    $media: $sm;
  } @else if $type == md {
    $media: $md;
  } @else if $type == lg {
    $media: $lg;
  } @else if $type == xl {
    $media: $xl;
  } @else if $type == 2xl {
    $media: $xl2;
  }

  @media #{$media} {
    @content;
  }
}

@import "src/styles/mixins";

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4);
  height: calc(100dvh - 64px);
  overflow: auto;
  padding: var(--spacing-8) var(--spacing-4) var(--spacing-6);

  @include media(notMobile) {
    height: auto;
    padding: var(--spacing-10);
  }

  .headerContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: var(--spacing-4);

    .titleWrapper {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-2);

      h2 {
        font-weight: var(--font-weight-bold);
        font-size: var(--typography-base-sizes-2x-large-font-size);
        line-height: var(--typography-base-sizes-2x-large-line-height);
        color: var(--base-foreground);
      }

      p {
        font-weight: var(--font-weight-normal);
        font-size: var(--typography-base-sizes-small-font-size);
        line-height: var(--typography-typography-components-small-line-height);
        color: var(--base-muted-foreground);
      }
    }
  }
  .content {
    font-weight: var(--font-weight-normal);
    font-size: var(--typography-base-sizes-base-font-size);
    line-height: var(--typography-base-sizes-base-line-height);
    color: var(--base-foreground);
  }
}

.placeholders {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: var(--spacing-8) var(--spacing-4) var(--spacing-6);

  @include media(notMobile) {
    padding: var(--spacing-10);
  }

  div:nth-child(1) {
    width: 100%;
    height: var(--height-h-8);
    border-radius: var(--border-radius-xl);
  }

  div:nth-child(2) {
    margin-top: var(--spacing-2);
    width: var(--width-w-36);
    height: var(--height-h-4);
    border-radius: var(--border-radius-md);
  }

  div:nth-child(3) {
    margin-top: var(--spacing-4);
    width: 100%;
    height: var(--height-h-36);
    border-radius: var(--border-radius-xl);
  }
}

.markdown {
  white-space: pre-wrap;

  ul,
  ol {
    margin-bottom: var(--spacing-4);
    padding-left: var(--spacing-8);
  }

  ul {
    list-style-type: none;

    & li:before {
      content: "-";
      position: relative;
      left: -16px;
    }
  }

  ol {
    list-style-type: decimal;
  }

  li {
    list-style-type: inherit;
    margin: 0;
  }

  h1 {
    margin-top: var(--spacing-8);
    font-weight: var(--font-weight-bold);
    font-size: var(--typography-base-sizes-2x-large-font-size);
    line-height: var(--typography-base-sizes-2x-large-line-height);
    color: var(--base-foreground);
  }

  h2 {
    margin-top: var(--spacing-6);
    font-weight: var(--font-weight-bold);
    font-size: var(--typography-base-sizes-large-font-size);
    line-height: var(--typography-base-sizes-large-line-height);
    color: var(--base-foreground);
  }

  h3,
  h4,
  h5,
  h6 {
    margin-top: var(--spacing-4);
    font-weight: var(--font-weight-semibold);
    font-size: var(--typography-base-sizes-base-font-size);
    line-height: var(--typography-base-sizes-base-line-height);
    color: var(--base-foreground);
  }

  p {
    margin-top: var(--spacing-2);
    font-weight: var(--font-weight-normal);
    font-size: var(--typography-base-sizes-base-font-size);
    line-height: var(--typography-base-sizes-base-line-height);
    color: var(--base-foreground);
  }

  pre {
    padding: var(--spacing-4);
    font-size: var(--typography-base-sizes-small-font-size);
    overflow-x: auto;
  }

  code {
    padding: var(--spacing-1) var(--spacing-2);
    font-size: var(--typography-base-sizes-small-font-size);
    color: var(--accent-color);
  }

  blockquote {
    margin: var(--spacing-4) 0;
    padding-left: var(--spacing-4);
    font-style: italic;
    color: var(--base-muted-foreground);
  }

  hr {
    border: none;
    margin: var(--spacing-6) 0;
  }

  img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: var(--spacing-4) 0;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin: var(--spacing-4) 0;
  }

  th,
  td {
    padding: var(--spacing-2);
    text-align: left;
  }

  th {
    font-weight: var(--font-weight-bold);
  }

  td {
    background: var(--base-background);
  }

  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

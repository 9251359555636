.menuItem {
  padding: var(--spacing-2);
  display: flex;
  align-items: center;
  gap: var(--spacing-2);
  border-radius: var(--border-radius-md);
  transition: background-color 0.15s, color 0.15s;

  &:focus {
    outline-color: var(--base-ring);
  }

  &:focus-visible {
    outline: solid var(--base-ring);
    outline-width: 2px;
  }

  &:hover {
    background-color: var(--base-sidebar-accent);
  }

  &.isActive {
    background-color: var(--base-sidebar-accent);

    .label {
      font-weight: var(--font-weight-medium);
    }
  }

  .label {
    font-weight: var(--font-weight-normal);
    font-size: var(--typography-base-sizes-small-font-size);
    line-height: var(--typography-typography-components-small-line-height);
    color: var(--base-sidebar-accent-foreground);
  }
}

@import "src/styles/mixins";

.content {
  width: 100%;
}

.sidebarButton {
  padding: var(--spacing-2) var(--spacing-4);

  @include media(notMobile) {
    display: none;
  }
}

.backButtonWrapper {
  padding: var(--spacing-6) var(--spacing-4) 0;
  display: block;
  width: max-content;

  @include media(notMobile) {
    display: none;
  }
}

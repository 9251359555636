.OverageCard {
  padding: var(--spacing-6);
  background-color: var(--base-card);
  border: 1px solid var(--base-border);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--shadow--sm);

  h2 {
    color: var(--base-card-foreground);
    font-weight: var(--font-weight-medium);
    font-size: var(--typography-base-sizes-small-font-size);
    line-height: var(--typography-base-sizes-small-line-height);
  }

  .used {
    margin-top: var(--spacing-2);
    color: var(--base-card-foreground);
    font-weight: var(--font-weight-bold);
    font-size: var(--typography-base-sizes-2x-large-font-size);
    line-height: var(--typography-base-sizes-2x-large-line-height);
  }

  .description {
    color: var(--base-muted-foreground);
    font-weight: var(--font-weight-normal);
    font-size: var(--typography-base-sizes-extra-small-font-size);
    line-height: var(--typography-base-sizes-extra-small-line-height);
  }
}

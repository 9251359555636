.emptyContainer {
  padding: var(--spacing-10);
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  svg {
    color: var(--base-foreground);
    width: 24px;
    height: 24px;
  }

  h2 {
    margin-top: var(--spacing-4);
    margin-top: var(--spacing-1);
    font-weight: var(--font-weight-bold);
    font-size: var(--typography-base-sizes-xlarge-font-size);
    line-height: var(--typography-base-sizes-xlarge-line-height);
    color: var(--base-foreground);
    max-width: var(--max-width-max-w-lg);
  }

  p {
    margin-top: var(--spacing-1);
    font-weight: var(--font-weight-normal);
    font-size: var(--typography-base-sizes-base-font-size);
    line-height: var(--typography-base-sizes-base-line-height);
    color: var(--base-muted-foreground);
    max-width: var(--max-width-max-w-lg);
  }
}

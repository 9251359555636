@import "src/styles/mixins";

.title {
  font-weight: var(--font-weight-semibold);
  font-size: var(--typography-base-sizes-large-font-size);
  line-height: var(--typography-base-sizes-large-font-size);
}

.description {
  margin-top: var(--spacing-1-5);
  font-weight: var(--font-weight-normal);
  font-size: var(--typography-base-sizes-small-font-size);
  line-height: var(--typography-base-sizes-small-line-height);
}

.closeButton {
  color: var(--base-foreground);
}

.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4);
  top: auto;
  bottom: 0;
  left: 0;
  transform: none;
  border-radius: var(--border-radius-lg) var(--border-radius-lg) 0 0 ;
  max-width: 100%;

  @include media(notMobile) {
    top: 50%;
    left: 50%;
    bottom: auto;
    transform: translate(-50%, -50%);
    max-width: 425px !important;
    border-radius: var(--border-radius-lg);
  }
}

.Checkbox {
  display: flex;
  align-items: center;
  gap: var(--spacing-2);

  .checkboxContainer {
    flex-shrink: 0;
    position: relative;
    width: var(--width-w-4);
    height: var(--height-h-4);

    .checkboxInput {
      opacity: 0;
      width: 0;
      height: 0;

      // &:disabled + .checkboxLabel {
      //   background-color: var(--base-muted-foreground);
      //   cursor: not-allowed;
      // }
    }

    .checkboxLabel {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 1px solid var(--base-primary);
      border-radius: var(--border-radius-sm);
      color: var(--base-primary-foreground);
      cursor: pointer;
      
      svg {
        opacity: 0;
      }
    }

    .checkboxInput:checked + .checkboxLabel {
      background-color: var(--base-primary);
      border: none;

      svg {
        opacity: 1;
      }
    }
  }

  .labelContainer {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-0-5);

    .title {
      font-weight: var(--font-weight-medium);
      font-size: var(--typography-base-sizes-small-font-size);
      line-height: var(--typography-typography-components-small-line-height);
      color: var(--base-foreground);
    }
  }
}

.disabled {
  .checkboxContainer {
    .checkboxLabel {
      opacity: var(--opacity-opacity-50);
      cursor: not-allowed;
    }
  }

  .labelContainer {
    .title {
      opacity: var(--opacity-opacity-70);
    }
  }
}
